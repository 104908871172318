/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab&display=swap');

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

@import 'spinner';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

//@import "~@syncfusion/ej2-base/styles/material.css";
//@import "~@syncfusion/ej2-angular-layouts/styles/material.css";

body {
  @media (prefers-color-scheme: dark){
    --ion-background-color: #1c1242;
    background: #001529;
  }
}

.detail-container {
  @media (prefers-color-scheme: dark) {
    background: #333241;
    color: white;
  }

  padding: 15px;
  border-radius: 10px;
  margin-bottom: 5px;
  margin-top: 10px;
}

.ant-spin-container {
  border-radius: 10px;
}

.pg-title-extra {
  @media (prefers-color-scheme: dark) {
    background: #333241;
  }

  padding: 15px;
  border-radius: 10px;
  text-align: right;
}

.pg-info-extra {
  @media (prefers-color-scheme: dark) {
    background: #333241;
  }

  padding: 10px;
  border-radius: 10px;
  text-align: right;
}

.ant-layout-sider-trigger {
  background: #001529;
}

.ant-modal-content, .ant-modal-header, .ant-modal-title, .ant-modal-close-icon {
  @media (prefers-color-scheme: dark) {
    background: #252232;
    color: white;
  }
}

.ant-menu, .ant-menu-vertical, .ant-menu-vertical>ul, .ant-menu-item-group-title, .ant-menu-submenu-arrow, .ant-menu-submenu-popup>.ant-menu {
  @media (prefers-color-scheme: dark) {
    background: #252232;
    color: white;
  }
}

.ant-menu-item>.ant-menu-item-disabled, .ant-menu-item-disabled>span, .ant-menu-submenu-disabled>.ant-menu-submenu-title>span {
  @media (prefers-color-scheme: dark) {
    background: #252232;
    color: #cccccc !important;
  }
}

.ant-menu-horizontal {
  @media (prefers-color-scheme: dark) {
    border-bottom: none;
  }
}

.ant-modal-header {
  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid #001529;
  }
}

.ant-modal-footer {
  @media (prefers-color-scheme: dark) {
    border-top: 1px solid #252232;
  }
}

.grid-main-content{
  min-height: 100vh;
  border-radius: 15px 0px 0px 15px;
  padding-top: 0.8em;

  overflow-y: scroll;

  @media (prefers-color-scheme: dark) {
    background: #252232;
  }

  @media (prefers-color-scheme: light) {
    background: #ededed;
  }

}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 50px;
  background: url('/assets/icon/fastAsset.png') no-repeat center center;
  background-size: contain;
  margin: 16px;
}

nz-header {
  background: #fff;
  padding: 0;
}

nz-content {
  margin: 0 16px;
}

nz-breadcrumb {
  margin: 16px 0;
}

.inner-content {
  padding: 24px;
  background: #fff;
  min-height: 360px;
}

nz-footer {
  text-align: center;
}

.submenu-banner {
  height: 40px;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 0 16px;
  overflow: hidden;
  line-height: 40px;
  text-overflow: ellipsis;
  background: #1c1242;
}

.page-title {
  font-family: 'Roboto Slab', serif;

  padding-left: 0.7em;

  @media (prefers-color-scheme: dark) {
    color: #EEEEEE;
  }

  @media (prefers-color-scheme: light) {
    color: #111111;
  }
}

.pgt-details {
  padding-left: 2.9em;
  margin-top: -1.2em;
  z-index: 9999;
  position: sticky;
}

.control-section {
  margin: 0 auto;
  width: 500px;
}

#defaultLayout {
  padding: 10px;
}

#defaultLayout .e-panel .e-panel-container {
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.text-align {
  line-height: 90px;
}

.ant-card.dashboard-metric {
    margin: 1.2em;
}

.ant-card {
  @media (prefers-color-scheme: dark) {
    color: #EEEEEE;
    background: #001529;
    border: 1px solid #333333;
  }
}

.ant-card h4, .ant-card h5 {
  @media (prefers-color-scheme: dark) {
    color: #EEEEEE;
  }
  margin-top: 0.2em;
}
.ant-card h4 {
  text-align: center;
}
.dashboard-metric p {
  font-size: xxx-large;
  text-align: center;
  margin-bottom: 0px;
}

.ant-page-header-back-button {
  @media (prefers-color-scheme: dark) {
    color: #EEEEEE;
  }
  @media (prefers-color-scheme: light) {
    color: #111111;
  }
  margin-right: -12px;
  font-size: 24px;
  padding-top: 9px;
}

.ant-select-dropdown {
  @media (prefers-color-scheme: dark) {
    /**
    color: #EEEEEE !important;
    background: #001529 !important;
    border: #2a2a2a 1px solid;
    */
  }
}

.ant-table, .ant-table-row, .ant-table-thead, th.ant-table-cell{
  @media (prefers-color-scheme: dark) {
    color: #EEEEEE !important;
    background: #001529 !important;
  }
}

.ant-table-thead, th.ant-table-cell{
  @media (prefers-color-scheme: dark) {
    color: #EEEEEE !important;
    background: #001630 !important;
  }
}

nz-form-label, .ant-form-item-required, .ant-form-item-label>label .ant-form-item-tooltip, .ant-form-item-control-input-content, .ant-form-item-label>label, .ant-form label {
  @media (prefers-color-scheme: dark) {
    color: #EEEEEE !important;
  }
}


.ant-empty, .nz-disable-td {
  @media (prefers-color-scheme: dark) {
    color: #EEEEEE;
    background: #001529 !important;
  }
}
.ant-empty:hover, .ant-empty:hover, .nz-disable-td:hover, .ant-table-cell-fix-left:hover, .ant-table-cell-fix-right:hover, .ant-table-cell:hover, .ant-table-row:hover {
  @media (prefers-color-scheme: dark) {
    color: #EEEEEE !important;
    background: #001630 !important;
  }
}
.ant-table-tbody>tr>td, .ant-table-thead {
  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid #001630 !important;
    background: #001529 !important;
  }
}

.ant-table-cell-fix-left, .ant-table-cell-fix-right {
  @media (prefers-color-scheme: dark){
    background-color: #1c1242;
  }
}

.fas-list-header {
  padding: 5px 12px 5px 5px;
}
.ant-form-item-label>label::after {
  content: '';
}
.ant-form-item-label>label {
	font-size: 16px;
        vertical-align: top;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector, .ant-input, .ant-input-number, .ant-input-number {
  @media (prefers-color-scheme: dark){
	  background: #252232;
	  border: none;
	  border-bottom: 1px solid #CCC;
	  color: white;
  }
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled), .ant-select-dropdown {
  @media (prefers-color-scheme: dark){
	  background: #001529;
	  border: none;
	  color: white;
  }
}
.ant-select-item {
  @media (prefers-color-scheme: dark){
	  background: #001529;
	  border: none;
	  color: #CCC;
  }
}
.ant-btn {
  @media (prefers-color-scheme: dark){
	  background: #001529;
	  border: #111529;
	  color: white;
  }
}
