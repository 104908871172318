.loader {
  color: #fff;
  position: fixed;
  box-sizing: border-box;
  left: -9999px;
  top: -9999px;
  width: 0;
  height: 0;
  overflow: hidden;
  z-index: 999999;
}
.loader:after,
.loader:before {
  box-sizing: border-box;
  display: none;
}
.loader.is-active {
  @media (prefers-color-scheme: light){
    background-color: rgba(255, 255, 255, 1);
  }


  @media (prefers-color-scheme: dark){
    --ion-background-color: #1c1242;
    background: #001529;
  }
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
.loader.is-active:after,
.loader.is-active:before {
  display: block;
}
@keyframes rotation {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}
@keyframes blink {
  0% {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}
.loader-double:after,
.loader-double:before {
  content: '';
  position: fixed;
  border-radius: 50%;
  border: 8px solid;
  animation: rotation 1s linear infinite;
}
.loader-double:after {
  width: 48px;
  height: 48px;
  border-color: #0066ff;
  border-left-color: transparent;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
}
.loader-double:before {
  width: 64px;
  height: 64px;
  border-color: goldenrod;
  border-right-color: transparent;
  animation-duration: 2s;
  top: calc(50% - 32px);
  left: calc(50% - 32px);
}
